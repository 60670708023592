import LazyLoad from "vanilla-lazyload";

let tree = [];
let projects = document.querySelectorAll('.project');
let imgs = document.querySelectorAll('.lazy');
let counter = 0;
let counterel = document.getElementById('counter-left');
let vh;

init();

function init() {

    resize();

    window.addEventListener('resize', resize);
    window.addEventListener('scroll', spy);

    loadimgs();
    spyvideos();

    uiheader();
    uifooter();

}

function resize() {

    vh = window.innerHeight;
    buildtree();

}

function loadimgs() {

    var lazyLoadInstance = new LazyLoad({
        'class_loaded': 'done',
        'threshold':2*vh
    })

}

function spyvideos() {

    let videos = document.querySelectorAll('video');

    for (let i = 0; i < videos.length; i++) {

        videos[i].addEventListener('play', (event) => {
            resize();
        });

    }

}

function buildtree() {

    let wh = window.scrollY

    for (let i = 0; i < projects.length; i++) {

        tree[i] = {};
        tree[i].el = projects[i];
        tree[i].top = projects[i].getBoundingClientRect().top + wh - 2;
        tree[i].bottom = projects[i].getBoundingClientRect().bottom + wh - 2;

    }

}

function spy() {

    let wh = window.scrollY;

    if ((wh >= tree[counter].bottom && counter < tree.length - 1) ||
        (wh <= tree[counter].top && counter != 0)) {

        findsection(wh);
        counterel.innerHTML = counter + 1;

    }

}

function findsection(wh) {

    for (let i = 0; i < tree.length; i++) {

        if (wh >= tree[i].top &&
            wh <= tree[i].bottom) {

            counter = i;
            break;

        }

    }

}

function uiheader() {

    let uihead = document.getElementById('ui-head');
    let uiindex = document.getElementById('ui-index');

    uihead.addEventListener('click', function() {

        uiindex.scrollIntoView();

    })

}

function uifooter() {

    let index = document.querySelectorAll('.information-list-entry');

    for (let i = 0; i < index.length; i++) {

        let slug = index[i].getAttribute('slug');
        let pel = document.querySelector('.project[slug="' + slug + '"');

        index[i].addEventListener('click', function() {

            pel.scrollIntoView();

        })

    }

}